import defaults from "./default.config"
import deepMergeObjects from "../assets/helpers/mergeObjects.js"

export default defineI18nConfig(() => (deepMergeObjects(defaults, {
	messages: {
		en: {
			//TODO: Maintain Alphabetical order!!!
			cookiesNotice: "We use analytical tools to help make your experience better. We’d love to have you" +
				" accept our cookies but we’ll always respect your data choices. Some cookies are necessary for our page to work.",
			discoverMore: "Discover More",
			homepageBody_addOns: 'Secure your Weekend Shower Pass or Limited Edition T-Shirt. Limited quantities available.',
			homepageBody_checkout: "It's time to check out. We can't wait to see you in the Woods!",
			homepageBody_confirmation: "A confirmation email has been sent to {email}.\nPlease note: If you purchased items on a payment plan, those tickets will not appear as usable tickets in your portal until fully paid.",
			homepageBody_donations: 'Donate directly to Ankors, 100% of donations collected go to the Ankors organization.',
			homepageBody_extras: "Finalize your cart with these wicked extras.",
			homepageBody_lodging: 'Enhance your Wicked Woods experience with these Camping and Glamping options. Use the icons above to navigate, or click "Next" to skip.\nPlease note that you will also be required to purchase a GA ticket for entry to the festival.',
			homepageBody_parking: "Parking passes are required for each vehicle entering the festival grounds. If you plan to camp with your car, or bring an RV, you will need a vehicle pass. If you don't have a vehicle, or want to leave your vehicle in the free parking lot, use the icons above to navigate, or click \"Next\" to skip.",
			homepageBody_tickets: 'Select and add tickets to your cart. If you already have your ticket(s), use the icons above to navigate, or click "Next" to skip.',
			homepageHeading_addOns: "Add-ons",
			homepageHeading_checkout: "You're wicked close!",
			homepageHeading_confirmation: "Thanks for your order, {name}!",
			homepageHeading_donations: "Donations",
			homepageHeading_extras: "Extras",
			homepageHeading_lodging: "Camping Packages",
			homepageHeading_parking: "Park n' Camp Options",
			homepageHeading_tickets: "Secure your ticket to the Woods!",
			lodging: "Camping",
			maintenance1: "Tickets sales will begin Oct 11th at 12pm MST",
			maintenance2: "We can't wait to see you in the Woods!",
			noLodgingItem: "Please add a Camping Package",
			parking: "Parking",
		},
		fr: {},
	}
})))
